import React from 'react';

class TestGeoLocation extends React.Component {
    state = {
        lat: 0,
        lng: 0
    };

    componentDidMount() {
        console.log('hello from amir');
        const options = {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 0
        };
        if ('geolocation' in navigator) {
            console.log('geolocation is enable');
            console.log(navigator.geolocation);
            if ('getCurrentPosition' in navigator.geolocation) {
                console.log('heeeeey navigator have getCurrentPosition');
            } else {
                console.log('sorry getCurrentPosition not exist');
            }
            navigator.geolocation.getCurrentPosition((location) => {
                console.log('in get current position');
                try {
                    console.log('your lat is ' + location.coords.latitude + ' and your lon is ' + location.coords.longitude);
                    this.setState({
                        lat: location.coords.latitude,
                        lng: location.coords.longitude
                    });
                } catch (e) {
                    console.log('we have error to access location object (in try cache)');
                }

            }, (error) => {
                console.log('hey we have error to read location from your device');
                console.log(JSON.stringify(error.message));
            }, options);
        } else {
            console.log('the geo location api is disable !!!');
        }
    }

    render() {
        return (
            <div>
                your lat is {this.state.lat} and your lng is {this.state.lng}
            </div>
        );
    }
}

export default TestGeoLocation;
